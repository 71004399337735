<script>
import AuditoriumForm from "@/views/menu/auditorium/auditoriumForm";
import Component, { mixins } from "vue-class-component";

@Component({
  inject: {
    auditoriumService: "auditoriumService",
    fileUploadService: "fileUploadService",
    toastService: "toastService"
  },
})
export default class AuditoriumNew extends mixins(AuditoriumForm) {
  formId = "new-auditorium-form";

  get fieldColumns() {
    return 2;
  }
  get actions() {
    return [
      {
        id: "save",
        type: "submit",
        text: this.translations.labels.button_save,
        leftIcon: "mdi-content-save-outline",
        disabled: () => {
          return this.fileUploadService.isUploading();
        },
        handle: this.save,
      },
    ];
  }
  async save(action, passesValidation) {
    this.errors = [];
    if (!passesValidation) {
      return;
    }
    try {
        await this.auditoriumService.create(this.model);
      this.$router.push("/auditorium/list");
      // eslint-disable-next-line no-undef
      toastService.success(this.translations.success.auditorium_create);
    } catch (error) {
      this.errors = this.formatApiErrors(error);
    }
  }

  getFormTitle() {
    return this.translations.pageTitles.auditoriums_new;
  }

  afterCreate() {
    this.model = {
      code: null,
      event: null,
      availableToGuest: false,
      hasVideoBox: false,
      localizations: [],
    };
    this.isReady = true;
  }
}
</script>
